<template>
  <div class="user-page container-fluid h-100">

    <div class="d-flex flex-column justify-content-between h-100">
      <div>

        <div class="h-top">
          <router-link :to="{path: '/user/login'}" class="button-back-empty">
            <span class="fa fa-arrow-left"></span>
          </router-link>
        </div>

        <div class="h-title">
          NEW PASSWORD
        </div>

        <form class="col-md-4 offset-md-4 h-form" @submit.prevent="doUserPasswordChange">

          <div class="form-group mb-3">
            <input
                v-model="password"
                :class="{'is-invalid': errors?.password}"
                type="password"
                class="form-control rounded-4"
                id="password-input"
                autocomplete="false"
                placeholder="Password" />
            <div class="invalid-feedback">{{ errors?.password }}</div>
          </div>

          <button class="btn btn-form mt-2" type="submit">Save</button>

        </form>
      </div>
      <div class="h-bottom">
        NEED A NEW ACCOUNT?
        <router-link to="/user/register">Sign up</router-link>
      </div>
    </div>

  </div>
</template>

<script>

export default {

  data: () => ({
    password: null
  }),

  async mounted() {

    if (this.isUser())
      return this.redirect('/');
  },

  methods: {

    async doUserPasswordChange() {

      let apiHolder = await this.sendApiPost(['api/user/password/change', {
        prove: this.$route.query?.prove,
        token: this.$route.query?.token
      }], {
        password: this.password
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/user/login');
    }
  }
}
</script>

<style scoped>

.h-title {
  padding-top: 80px;
}

</style>